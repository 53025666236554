<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{on, attrs}">
      <v-btn text color="primary" v-on="on" @click="loadUnfilledFields" v-bind="attrs" class="mx-2">Générer</v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-toolbar-title class="white--text">Générer le document</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-2">
        <div v-if="loading_unfilled">
          <v-progress-circular color="primary" indeterminate />
          Chargement des champs non complets...
        </div>
        <div v-else-if="unfilled_fields.length > 0">
          <p class="orange--text font-weight-bold">Les champs suivants sont potentiellement non complets et sont requis pour ce document.</p>
          <ul>
            <li v-for="(field, index) in unfilled_fields" :key="index">{{field}}</li>
          </ul>
        </div>
        <h3 class="primary--text">Champs à remplir</h3>
        <div v-if="completed_fields.length === 0" class="font-italic">Pas de champ unique à remplir.</div>
        <div v-for="field in completed_fields" :key="field.id">
          <v-text-field v-model="field.value" :label="field.name"></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="createDocument(modelDoc.id)" :disabled="generating"  :color="generating ? 'grey' :'primary'" class="mx-2">Générer</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'GenerateDocumentForm',
  props: {
    modelDoc: Object,
    dossier: Object,
  },
  async mounted() {
    let customFields = this.modelDoc.custom_fields;
    this.completed_fields = customFields.map((customField) => ({...customField, value: ""}));
  },
  data() {
    return {
      completed_fields: [],
      unfilled_fields: [],
      generating: false,
      dialog: false,
      loading_unfilled: false,
      ask_unfilled: false,
    };
  },
  methods: {
    async createDocument(modelDocId) {
      this.generating = true;
      await this.$store.dispatch("documents/postDocument", {model_doc: modelDocId, completed_fields: this.completed_fields, dossier: this.dossier.id});
      this.generating = false;
      this.dialog = false;
      this.$emit("reload");
    },
    async loadUnfilledFields() {
      if(this.ask_unfilled === false) {
        this.loading_unfilled = true;
        this.unfilled_fields = await this.$store.dispatch("modeldocs/getDossierUnfilledFields", {
          dossierId: this.dossier.id,
          modelDocId: this.modelDoc.id,
        });
        this.loading_unfilled = false;
        this.ask_unfilled = true;
      }
    }
  },
}
</script>
<style scoped>
canvas {
  border: darkgrey 1px solid;
}
</style>